import { graphql, StaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react'
import FilmCard from '../container/work-card'
import '../scss/entry.scss'
import Page from '../container/page';
import { Helmet } from 'react-helmet';

const query = graphql`
query AllFilms {
  allStrapiFilms {
    nodes {
      Thumbnail {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [WEBP, PNG], layout:FULL_WIDTH)
        }
      }
      Title
      Category
      Description
      OpenURL
    }
  }
}
`

export default function Films() {
  return [
    <Helmet>
      <title>Joshua Carroll | Films</title>
    </Helmet>,
    <Page>
      <div className="stills-container">
        <StaticQuery 
          query={query}
          render={
            (d) => {
              const data = d.allStrapiFilms.nodes
              return data.map(e => {
                return (<FilmCard link={e.OpenURL} title={e.Title} description={e.Description} image={getImage(e.Thumbnail)} tag={e.Category.replace('_', ' ')}  />    )
              })
            }
          }
        />
      </div>
    </Page>
  ]
}
